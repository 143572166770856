import React from 'react';
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import StartProject from '../components/Common/StartProject';

const Home = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <StartProject />
            <Footer />
        </Layout>
    );
}

export default Home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;